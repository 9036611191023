<template>
  <div class="container is-fluid">
    <div class="columns grid_height">
      <div class="column company_image_bg">
        <div class="is-flex quote-text">
          <img class="quote-img" src="../../assets/images/quote.png" alt="" />
          <h1 class="gradient-text">
            Good company in a journey makes the way seem shorter
          </h1>
        </div>
        <div class="author">
          <p class="author-text">Izak Walton</p>
        </div>
      </div>

      <div class="column form-company">
        <img class="company-logo" src="/images/rise_logo_red.png" alt="" />
        <h1 class="create_company_text">Create or Join Company</h1>
        <p class="child-text mb-6">
          Build your own business or join a growing company
        </p>

        <div @click="createCompany()" class="box is-flex click">
          <img class="company-img" src="/images/building.png" alt="" />
          <h3 class="create-company-text">Create Company</h3>
        </div>
        <div class="box is-flex not-allowed">
          <img class="company-img" src="/images/join.png" alt="" />
          <h3 class="create-company-text">Join Company</h3>
        </div>
        <div class="text-footer">
          <p>
            PT Roketin Kreatif Teknologi. Copyright
            {{ new Date().getFullYear() }}. RISE {{ appVersion }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appVersion } from '@/version'
export default {
  methods: {
    createCompany() {
      this.$router.push('create-company')
    },
  },
  computed: {
    appVersion: () => appVersion,
  },
}
</script>

<style></style>
